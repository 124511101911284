import { images } from './images';

import type { TagData, TagType } from './LinearTag';
import type { CountryCode, LanguageCode } from '@Types/Base';

type TagsData = Partial<Record<TagType, TagData[]>>;
type LocalData = Partial<Record<LanguageCode, TagsData>>;

const tags: Partial<Record<CountryCode, LocalData>> = {
  RUS: {
    ru: {
      extra: [
        {
          icon: images.fire,
          color: '#ffddca',
          text1: 'Экстраскидка',
          text2: 'до 21 августа',
          startData: new Date(2024, 7, 5, 0, 0, 0),
          endDate: new Date(2024, 7, 21, 23, 59, 59),
        },
        {
          icon: images.fire,
          color: '#ffddca',
          text1: 'Экстраскидка',
          text2: 'до 8 сентября',
          startData: new Date(2024, 7, 22, 0, 0, 0),
          endDate: new Date(2024, 8, 8, 23, 59, 59),
        },
      ],
      kits: [
        {
          icon: images.plus,
          color: '#F0E5CF',
          text1: 'Вместе дешевле',
          text2: 'Кровать + Матрас',
          startData: new Date(2024, 6, 10, 10, 0, 0),
          endDate: new Date(2024, 8, 7, 23, 59, 59),
        },
      ],
    },
  },
  KAZ: {
    ru: {
      extra: [
        {
          icon: images.fire,
          color: '#ffddca',
          text1: 'Экстраскидка',
          text2: 'до 21 августа',
          startData: new Date(2024, 7, 5, 0, 0, 0),
          endDate: new Date(2024, 7, 21, 23, 59, 59),
        },
        {
          icon: images.fire,
          color: '#ffddca',
          text1: 'Экстраскидка',
          text2: 'до 8 сентября',
          startData: new Date(2024, 7, 22, 0, 0, 0),
          endDate: new Date(2024, 8, 8, 23, 59, 59),
        },
      ],
    },
    kk: {
      extra: [
        {
          icon: images.fire,
          color: '#ffddca',
          text1: 'Экстраскидка',
          text2: '21 тамызға дейін',
          startData: new Date(2024, 7, 5, 0, 0, 0),
          endDate: new Date(2024, 7, 21, 23, 59, 59),
        },
        {
          icon: images.fire,
          color: '#ffddca',
          text1: 'Экстраскидка',
          text2: '8 қыркүйекке дейін',
          startData: new Date(2024, 7, 22, 0, 0, 0),
          endDate: new Date(2024, 8, 8, 23, 59, 59),
        },
      ],
    },
  },
};

export default tags;
