import { memo, useCallback } from 'react';
import { IconLike } from '@divlab/divanui/icons';

import { useCompare } from '@Queries/Compare';

import type { FC } from 'react';
import type { ProductData } from '@Types/Product';
import type { LikeProps } from '@divlab/divanui/icons';

export interface ProductLikeProps extends LikeProps {
  product: ProductData;
  hash?: string;
  label?: string;
}

const ProductLike: FC<ProductLikeProps> = (props) => {
  const { product, hash, label, onClick, ...restProps } = props;
  const productHash = hash || product?.hash;
  const { isInCompare, add, remove } = useCompare(product.id, productHash);

  const handleClick = useCallback(
    (e) => {
      if (isInCompare) {
        remove({ productId: product.id, payload: { hash: productHash } });
      } else {
        add({
          productId: product.id,
          payload: {
            analytics: { product, label },
            hash: productHash,
            parameterValues: product?.constructorParameterValues,
          },
        });
      }

      if (onClick) onClick(e);
    },
    [isInCompare, onClick, remove, product, add, productHash, label],
  );

  return <IconLike {...restProps} liked={isInCompare} onClick={handleClick} />;
};

export default memo(ProductLike);
